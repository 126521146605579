import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import "../Styles/contact.css";

export default function Contact() {
  return (
    <section className="contact" id="contactt">
      <div className="parent">
        <div className="div2">
          <p className="div2-p"></p>
        </div>
      </div>
      <div className="get-in-touch-container">
        <div className="get-in-touch-header">
          <h3>GET IN TOUCH WITH US</h3>
        </div>
        <div className="contact-methods">
          <div className="contact-method">
            <div className="map"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
            <p>Main Office: Precinct 1, Zenith Plaza 8th Floor, Main Jinnah Avenue, Near Main Gate, Bahria Town Karachi, Karachi, Sindh, Pakistan.</p>
          
            <p className="midway">Midway Office: Line 3 Midway Commercial Building No. 215B, Bahria Town Karachi, Karachi, Sindh, Pakistan</p>
          </div>
          <div className="contact-method">
            <div className="phone">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <p>+92 300-4900-198</p>
            <p className="ShafiqBhai"> CEO- Shafiq Ur Rahman</p>
          </div>
          <div className="contact-method">
            <div className="mail">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <p>info.arqamassociates@gmail.com</p>
          </div>
        </div>
      </div>
      
      <div className="socialmedias">
       <p className="visitus"> You can also visit us on social media :) </p>
       <div className="icons-containerr">
    <a href="https://www.instagram.com/arqamassociates/"><FaInstagram /></a>
    <a href="https://www.facebook.com/arqamassociates/"><FaFacebookF /></a>
    <a href="https://wa.me/923004900198"><FaWhatsapp /></a>
    <a href="https://www.youtube.com/c/ArqamAssociates"><FaYoutube /></a>
    <a href="https://www.tiktok.com/@arqam.associates"><FaTiktok /></a>
  </div>
      </div>
    </section>
  );
}
