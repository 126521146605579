import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import AboutUs from "./Components/AboutUs";
import Properties from "./Components/Properties"; 
import Contact from "./Components/Contact"
import Navbar from "./Components/Navbar";
import Section from "./Components/Section";
import Footer from "./Components/Footer";
import LoadingScreen from "./Components/Loadingscreen";
import "./styles.css";


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <div className="app">
      {isLoading ? (
        <div className="loading-screen-container">
          <LoadingScreen />
        </div>
      ) : (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Section />} />
            <Route path="Aboutus" element={<AboutUs />} />
            <Route path="Properties" element={<Properties />} />
            <Route path="Contact" element= {<Contact />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
