import React from 'react';
import "../Styles/Loadingscreen.css";

const LoadingScreen = () => (
  <>
  <div class="spinner">
<div class="loader l1"></div>
<div class="loader l2"></div>
</div>
</>
);

export default LoadingScreen;
