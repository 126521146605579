import React from "react";
import "../Styles/section1.css"; // Make sure the path is correct
import bahriaImage from "../images/bahria.jpg";
import arqam from "../images/aaa.jpg";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

export default function Navbar() {
  const divStyle = {
    backgroundImage: `url(${bahriaImage})`,
    backgroundSize: "cover",
  };
  return (
    <>
      <section className="home" id="home"style={divStyle}>
        <div className="home-content" >
        <img src={arqam} alt="ArqamAssociates" className="logo1" />
         <div>
         <p className="home-p">COME TO THE FUTURE OF PAKISTAN
         </p>
         <h6 className="h6">Come To Bahria Town Karachi</h6>
         </div>
         <div className="socialmedias">
         <div className="icons-container">
    <a href="https://www.instagram.com/arqamassociates/"><FaInstagram /></a>
    <a href="https://www.facebook.com/arqamassociates/"><FaFacebookF /></a>
    <a href="https://wa.me/923004900198"><FaWhatsapp /></a>
    <a href="https://www.youtube.com/c/ArqamAssociates"><FaYoutube /></a>
    <a href="https://www.tiktok.com/@arqam.associates"><FaTiktok /></a>
  </div>
         </div>
        </div>
      </section>
      
      <section className="project-page" >
  
  <div className="projects">
    <h4 className="heading">OUR PROJECT'S</h4>
  </div>
  <div className="youtube" id="video">
    {/* <h6 className="projectname">Project Name ...</h6> */}
   <div className="wrapper">
    <iframe  src="https://www.youtube.com/embed/edDZTbGyX3Y?si=SGYfdIw1tZeukNuT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
   </div>
   <div>
    <div className="projectdetails" >
      <ol>
        <li>
        projects details
        </li>
        <li>
          project details  </li>
      </ol>
    </div>
  </div>
   </div>


 
  
</section>
    </> 
  );
}
