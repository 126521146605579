import React from "react";
import arqam from "../images/aaa.jpg";
import "../Styles/footer.css";

const Footer = () => (
  <div className="footer">
    <div className="footer-div1">
    <img src={arqam} alt="ArqamAssociates" className="footer-logo" />
    </div>
    <div className="footer-div2">
    <p className="footer-p">Precinct 1, Zenith Plaza 8th Floor, Main Jinnah Avenue, Near Main Gate, Bahria Town Karachi, Karachi, Sindh, Pakistan.</p>
    <p className="footer-p">&copy; Arqam Associates All Rights Reserverd</p>
    </div>
    <div className="footer-div2">
    <p className="footer-p">Timings:</p>
    <p className="footer-p">Monday-Sunday 11:00 AM To 08:00 PM</p>
    </div>


    
  </div>
);

export default Footer;