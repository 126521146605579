import React from "react";
import "../Styles/about.css"; 
import Shafiq from "../images/Shafiq11.jpg";
import { FaQuoteLeft } from "react-icons/fa";



export default function about() {
  return (
    <>
       <section className="about" id="about">
       <div className="about-div">
       <img src={Shafiq} alt="ArqamAssociates" className="about-img" />
        <p className="aboutp">
        <div className="quotes"><FaQuoteLeft/></div>In 2017, I embarked on a journey to Bahria Town Karachi, propelled by the promise of uncharted opportunities. Arriving on a modest motorcycle, I was captivated by the sheer beauty of this town, with its potential waiting to be unlocked. Fast forward to January 2019, and our first steps were taken within the confines of an office nestled in Midway Commercial. As the months unfolded, so did our aspirations. By June 2020, our determination led us to acquire an entire plaza, one that would become the cornerstone of our endeavors. The turning point came in 2021 when the first bricks of our vision were laid in the form of construction. Today, as I stand before you, I am humbled to say that we have triumphed over challenges, delivering six resounding projects, all backed by the endorsement of Bahria Town Karachi's esteemed Head Office. Our identity is forged in the fires of integrity, an indomitable commitment to our craft, and an unwavering dedication to serve with passion. Our influence knows no bounds, extending beyond these city limits. With each passing day, our footprint grows, reaching Islamabad, Lahore, Peshawar, and beyond. Having tasted the fragility of investments firsthand, I understand the trust you place in us. Coming from a background where resources were scarce, I pledge to serve you with a dedication that knows no limits. Let us build not just structures, but dreams, aspirations, and a future that resonates with the echo of your success.
        <div className="about-ceo">
          <h2>Shafiq Ur Rahman (CEO)</h2>
        </div>
        </p>
       </div>
      </section>
    </>
  );
}

