import React from "react";
import "../Styles/properties.css";
import Zameen from "../images/zameen.jpg";

export default function Properties() {
  return (
    <section className="properties" id="propertiess">
      <div className="parent">
        <div className="div2">
          <p className="div2-p">Find us on Zameen.com! :)</p>
          <a href="https://www.zameen.com/agents/Karachi/Arqam_Associates-191365/">
            <img src={Zameen} alt="Zameen" className="zameen-img" />
          </a>
          <p className="zameen-text">Click on image to visit us</p>
        </div>
      </div>
    </section>
  );
}
